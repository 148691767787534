import { db } from "./firebaseConfig";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { GYM_COLLECTION } from "./firebaseConfig";

interface GymState {
  members: string[];
  affiliates: string[];
  otherGyms: string[];
  name: string;
  id: string;
  address: string;
  phone: string;
  geoPoint: import("firebase/firestore").GeoPoint | null;
}

function saveGymStateToFirebase(gymState: GymState) {
    setDoc(doc(db, GYM_COLLECTION, gymState.id), gymState);
}

export { saveGymStateToFirebase };
