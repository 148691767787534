import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./Features/Login/user";
import gymReducer from "./Features/Gym/gym";
import localMemberReducer from "./Features/Members/members";

export const store = configureStore({
  reducer: {
    user: userReducer,
    gym: gymReducer,
    localMembers: localMemberReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
