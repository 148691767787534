import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { doc, deleteDoc } from "firebase/firestore";
import { db, MEMBER_COLLECTION } from "../../../../Firebase/firebaseConfig";

const initialState: LocalMembers = {
  members: [],
};

const memberSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setMembers(state, action: PayloadAction<MemberState[]>) {
      state.members = action.payload;
    },
    addMember(state, action: PayloadAction<MemberState>) {
      state.members.push(action.payload);
    },
    removeMember(state, action: PayloadAction<string>) {
      state.members = state.members.filter(
        (member) => member.id !== action.payload
      );
      const docRef = doc(db, MEMBER_COLLECTION, action.payload);
      deleteDoc(docRef);
    },
    sortMembersAtoZ(state) {
      state.members.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        return 1;
      });
    },
    sortMembersDueDate(state) {
      state.members.sort((a, b) => {
        if (a.membershipExpiration < b.membershipExpiration) {
          return -1;
        }
        return 1;
      });
    },
  },
});

export const { setMembers, addMember,removeMember, sortMembersAtoZ, sortMembersDueDate } =
  memberSlice.actions;
export const selectUser = (state: RootState) => state.user;
export default memberSlice.reducer;
