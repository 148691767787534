import React from 'react'
import Construction from '../../Components/Construction'
import Schedule from '../../Components/Schedule'
export default function Home() {
  return (
    <div className="dashboard-main">
        <Schedule />
    </div>
  )
}
