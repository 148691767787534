import React, { useState, useCallback, useMemo, useEffect } from 'react'
import "./style.scss"
import Modal from './Modal'
import { Calendar, Event, momentLocalizer, SlotInfo, Views, } from 'react-big-calendar'
import moment from 'moment'
import { SCHEDULE_COLLECTION, db } from '../../Firebase/firebaseConfig';
import { getDoc, collection, doc, setDoc } from 'firebase/firestore';
import { useAppSelector } from '../../Utilities/Redux/hooks'
import InfoModal from './InfoModal'

const localizer = momentLocalizer(moment)

export default function Schedule(props: any) {
  const [myEvents, setMyEvents] = useState<ScheduleEvent[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [start, setStart] = useState<Date | null>(null)
  const [end, setEnd] = useState<Date | null>(null)
  const [view, setView] = useState<"day" | "week" | "month" | "work_week" | "agenda">(Views.MONTH)
  const { userID } = useAppSelector(state => state.user);
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState<ScheduleEvent | null>(null)
  const [defaultDate, setDefaultDate] = useState<Date>(new Date())
  const [scrollToTime, setScrollToTime] = useState<Date>(new Date())

  function handleSaveEvents(newEvents: ScheduleEvent[] | null = null) {
    if (!userID) return;
    const scheduleRef = doc(db, SCHEDULE_COLLECTION, userID);
    setDoc(scheduleRef, {
      events: newEvents || myEvents,
    }, { merge: false });
  }

  function handleSelectSlot({ start, end }: SlotInfo) {
    if (view === "month") {
      setScrollToTime(start);
      setDefaultDate(start);
      setView("day");
    } else {
      setStart(start)
      setEnd(end)
      setModalOpen(true)
    }
  }

  const handleSelectEvent = useCallback(
    (event: ScheduleEvent) => {
      setSelectedEvent(event as ScheduleEvent)
      setShowInfoModal(true)
    },
    []
  )

  function handleDeleteEvent(event: ScheduleEvent) {
    const newEvents = myEvents.filter((e: ScheduleEvent) => e.id !== event.id)
    setMyEvents(newEvents)
    setShowInfoModal(false)
    handleSaveEvents(newEvents)
  }
  function handleDeleteReoccuringEvent(event: ScheduleEvent) {
    const newEvents = myEvents.filter((e: ScheduleEvent) => e.reoccuringID !== event.reoccuringID)
    setMyEvents(newEvents)
    setShowInfoModal(false)
    handleSaveEvents(newEvents)
  }


  useEffect(() => {
    if (myEvents.length) handleSaveEvents()
  }, [myEvents])

  useEffect(() => {
    if (!userID) return;
    const scheduleRef = doc(db, SCHEDULE_COLLECTION, userID);
    getDoc(scheduleRef).then((doc) => {
      if (doc.exists()) {
        const { events } = doc.data();
        if (events) {
          setMyEvents(events.map((event: any) => ({
            ...event,
            start: new Date(event.start.seconds * 1000),
            end: new Date(event.end.seconds * 1000),
          })));
        }
      }
    })
  }, [userID])

  return (
    <div className="calendar-container">
      <Calendar
        // defaultDate={defaultDate}
        date={defaultDate}
        onNavigate={useCallback((newDate: any) => setDefaultDate(newDate), [setDefaultDate])}
        view={view}
        onView={useCallback((newView: any) => setView(newView), [setView])}
        events={myEvents}
        localizer={localizer}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        longPressThreshold={0}
        selectable
        scrollToTime={scrollToTime}
      />
      {modalOpen && <Modal
        hide={setModalOpen}
        setEvents={setMyEvents}
        start={start}
        setStart={setStart}
        end={end}
        setEnd={setEnd}
        save={handleSaveEvents}
      />}
      {showInfoModal && selectedEvent && <InfoModal
        data={selectedEvent}
        show={showInfoModal}
        setShow={setShowInfoModal}
        deleteEvent={handleDeleteEvent}
        deleteReoccuringEvent={handleDeleteReoccuringEvent}
      />}
    </div>
  )
}
