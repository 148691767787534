import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './Utilities/Redux/store';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Members from './Pages/Members';
import Loading from './Pages/Loading';
import GymSignUp from './Pages/GymSignUp';
import DashboardTemplate from './Components/DashboardTemplate';
import Home from './Pages/Home';
import Settings from './Pages/Settings';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const BrowserRouter = createBrowserRouter([
  {
    path: '/',
    element: <App />
  },
  {
    path: '/dashboard',
    element: <DashboardTemplate />,
    children:[
      {
        path: '/dashboard/members',
        element: <Members />
      },
      {
        path: '/dashboard/home',
        element: <Home />
      },
      {
        path: '/dashboard/settings',
        element: <Settings />
      }
    ]
  },
  {
    path: '/loading',
    element: <Loading />
  },
  {
    path: '/welcome',
    element: <GymSignUp />
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={BrowserRouter} />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
