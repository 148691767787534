import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../Utilities/Redux/hooks'
import { store } from '../../Utilities/Redux/store';
import { setUser, loginUser } from '../../Utilities/Redux/Features/Login/user'
import { setGym } from '../../Utilities/Redux/Features/Gym/gym'
import { useNavigate } from 'react-router-dom'
import { GYM_COLLECTION } from '../../Firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../Firebase/firebaseConfig';
import { helix } from 'ldrs'
import "./styles.scss"

helix.register()

export default function Loading() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const user = useAppSelector(state => state.user.userID)
    const gym = useAppSelector(state => state.gym)

    async function lookUpGymInDB(userID: string) {
        const docRef = doc(db, GYM_COLLECTION, userID)
        getDoc(docRef).then((doc) => {
            if (doc.exists()) {
                dispatch(setGym(doc.data() as GymState));
            } else {
                navigate('/welcome');
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
            // auth.signOut()
            navigate('/');
        });
    }

    auth.onAuthStateChanged((user) => {
        if (user) {
            dispatch(loginUser(user.uid))
            lookUpGymInDB(user.uid)
        } else {
            console.log("No user signed in")
            console.log(auth.currentUser)
            // auth.signOut()
            navigate('/');
        }
    });

    useEffect(() => {
        if (user) lookUpGymInDB((user))
    }, [user])

    useEffect(() => {
        if (gym && gym.id) {
            setTimeout(() => { navigate('/dashboard/members') }, 1000)
        }
    }, [gym])

    return (
        <div className="loading-body">
            <l-helix
                size="150"
                speed="2.5"
                color="black"
            ></l-helix>
        </div>
    )
}
