import React, { useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../../Utilities/Redux/hooks';
import Modal from '../../../Components/Modal';
import './style.scss';
import { MEMBER_COLLECTION, db } from "../../../Firebase/firebaseConfig";
import { collection, addDoc, getDocs, query, where, doc, deleteDoc, setDoc, Timestamp } from 'firebase/firestore';
import { addMember } from '../../../Utilities/Redux/Features/Gym/gym';
import { addMember as addMemberLocal, sortMembersAtoZ, sortMembersDueDate } from '../../../Utilities/Redux/Features/Members/members';
import { store } from '../../../Utilities/Redux/store';
import { saveGymStateToFirebase } from "../../../Firebase/stateSync";

export default function ControlPanel({ showHidden, setShowHidden }: { showHidden: boolean, setShowHidden: React.Dispatch<React.SetStateAction<boolean>> }) {
  const [showNewMemberModal, setShowNewMemberModal] = React.useState<boolean>(false);
  const dispatch = useAppDispatch()
  const gym = useAppSelector(state => state.gym)
  const nameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const emergencyContactNameRef = useRef<HTMLInputElement>(null);
  const emergencyContactPhoneRef = useRef<HTMLInputElement>(null);
  const joinDateRef = useRef<HTMLInputElement>(null);
  const membershipExpirationRef = useRef<HTMLInputElement>(null);
  const mobileScreen = window.innerWidth < 768 ? true : false;

  let curr = new Date();
  curr.setDate(curr.getDate());
  const date = curr.toISOString().substring(0, 10);

  function handleAddNewMember() {
    const join = new Date(joinDateRef.current?.value || Date.now())
    join.setDate(join.getDate() + 1)
    const expire = new Date(membershipExpirationRef.current?.value || Date.now())
    expire.setDate(expire.getDate() + 1)

    const newMemberRef = doc(collection(db, MEMBER_COLLECTION));

    const newMember: MemberState = {
      id: newMemberRef.id,
      name: nameRef.current?.value || '',
      phone: phoneRef.current?.value || '',
      email: emailRef.current?.value || '',
      emergencyContactName: emergencyContactNameRef.current?.value || '',
      emergencyContactPhone: emergencyContactPhoneRef.current?.value || '',
      joinDate: Timestamp.fromDate(join),
      membershipExpiration: Timestamp.fromDate(expire),
      hidden: false,
      stripeID: null,
      paymentUpToDate: false
    }

    setDoc(newMemberRef, newMember);
    dispatch(addMember(newMemberRef.id))
    dispatch(addMemberLocal(newMember))
    setShowNewMemberModal(false)
  }

  useEffect(() => {
    if(gym?.id) saveGymStateToFirebase(gym);
  }, [gym])

  return (
    <div className="control-panel-main">
      <button className='custom-button-primary' onClick={() => setShowNewMemberModal(true)} >New{mobileScreen ? "" : " Member"}</button>
      <button className='custom-button-primary' onClick={() => dispatch(sortMembersAtoZ())} >{mobileScreen ? "" : "Sort "}A-Z</button>
      <button className='custom-button-primary' onClick={() => dispatch(sortMembersDueDate())} >{mobileScreen ? "" : "Sort "} Date</button>
      <button className='custom-button-primary' onClick={() => setShowHidden(!showHidden)} >{mobileScreen ? null : (showHidden ? 'Hide' : 'Show')} Inactive</button>
      <Modal setShow={setShowNewMemberModal} show={showNewMemberModal}>
        <div className="new-member-body">

          <label htmlFor="member-name">Member Name</label>
          <input ref={nameRef} id="member-name" type="text" placeholder='name' />

          <label htmlFor="member-phone">Phone</label>
          <input ref={phoneRef} id="member-phone" type="text" placeholder='number' />

          <label htmlFor="member-email">Email</label>
          <input ref={emailRef} id="member-email" type="text" placeholder='email' />

          <label htmlFor="member-emergency-contact-name">Emergency Contact Name</label>
          <input ref={emergencyContactNameRef} id="member-emergency-contact-name" type="text" placeholder='name' />

          <label htmlFor="member-emergency-contact-phone">Emergency Contact Phone</label>
          <input ref={emergencyContactPhoneRef} id="member-emergency-contact-phone" type="text" placeholder='number' />

          <label htmlFor="member-join-date">Join Date</label>
          <input defaultValue={date} ref={joinDateRef} id="member-join-date" type="date" placeholder='date' />

          <label htmlFor="member-membership-expiration">Membership Expiration</label>
          <input defaultValue={date} ref={membershipExpirationRef} id="member-membership-expiration" type="date" placeholder='date' />

          <button className='custom-button-primary' onClick={handleAddNewMember}>Add Member</button>
        </div>
      </Modal>
    </div>
  )
}
