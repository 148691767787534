import React, { useEffect, useState, useRef } from 'react'
import "./style.scss";
import MemberStats from './MemberStats';
import ControlPanel from './ControlPanel';
import Modal from '../../Components/Modal';
import { Timestamp } from 'firebase/firestore';
import { MEMBER_COLLECTION, db } from '../../Firebase/firebaseConfig';
import { getDoc, collection, doc, setDoc } from 'firebase/firestore';
import { useAppSelector, useAppDispatch } from '../../Utilities/Redux/hooks';
import { setMembers, removeMember } from '../../Utilities/Redux/Features/Members/members';
import { spawn } from 'child_process';

export default function Members() {
    // const [members, setMembers] = useState<MemberState[]>([])
    const members = useAppSelector(state => state.localMembers.members)
    const dispatch = useAppDispatch()
    const [showHidden, setShowHidden] = useState<boolean>(false);
    const [showNewMemberModal, setShowNewMemberModal] = useState<boolean>(false);
    const [showEditMemberModal, setShowEditMemberModal] = useState<boolean>(false);
    const [memberSelected, setMemberSelected] = useState<MemberState | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const gym = useAppSelector(state => state.gym)
    const nameRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const emergencyContactNameRef = useRef<HTMLInputElement>(null);
    const emergencyContactPhoneRef = useRef<HTMLInputElement>(null);
    const joinDateRef = useRef<HTMLInputElement>(null);
    const membershipExpirationRef = useRef<HTMLInputElement>(null);
    const memberHidden = useRef<HTMLInputElement>(null);
    const [joinDate, setJoinDate] = useState<string>(new Date().toISOString().substring(0, 10));
    const [membershipExpiration, setMembershipExpiration] = useState<string>(new Date().toISOString().substring(0, 10));
    const mobileScreen = window.innerWidth < 768 ? true : false;

    // payment varibles
    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
    const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
    const [paymentError, setPaymentError] = useState<string | null>(null);
    const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);
    const stripeData = useAppSelector(state => state.gym.stripeData || null);
    const [paymentIntent, setPaymentIntent] = useState<string | null>(null);
    const [paymentAmount, setPaymentAmount] = useState<number>(0);
    const [showCheckout, setShowCheckout] = useState<boolean>(false);


    function handleRemoveMember(id: string | null | undefined) {
        if (!id) return;
        dispatch(removeMember(id));
        setShowNewMemberModal(false);
    }

    function handleEditMember() {
        setShowNewMemberModal(false);
        setShowEditMemberModal(true);
    }

    function handleSaveMember(memberID: string) {
        const join = new Date(joinDateRef.current?.value || Date.now())
        join.setDate(join.getDate())
        const expire = new Date(membershipExpirationRef.current?.value || Date.now())
        expire.setDate(expire.getDate())

        const newMemberRef = doc(collection(db, MEMBER_COLLECTION), memberID);

        const newMember: MemberState = {
            id: newMemberRef.id,
            name: nameRef.current?.value || '',
            phone: phoneRef.current?.value || '',
            email: emailRef.current?.value || '',
            emergencyContactName: emergencyContactNameRef.current?.value || '',
            emergencyContactPhone: emergencyContactPhoneRef.current?.value || '',
            joinDate: Timestamp.fromDate(join),
            membershipExpiration: Timestamp.fromDate(expire),
            hidden: memberHidden.current?.checked || false,
            stripeID: memberSelected?.stripeID || null,
            paymentUpToDate: memberSelected?.paymentUpToDate || false
        }

        setDoc(newMemberRef, newMember, { merge: true },);
        setShowEditMemberModal(false);
    }

    function handleDateChange(e: React.ChangeEvent<HTMLInputElement>) {
        return e.target.value;
    }

    function setMemberAsPaid() {
        console.log("set member as paid")
        const updatedMember = { ...memberSelected as MemberState, paymentUpToDate: true }
        setMemberSelected(updatedMember)
        const newMemberRef = doc(collection(db, MEMBER_COLLECTION), memberSelected?.id as string);
        setDoc(newMemberRef, updatedMember, { merge: true },);
        setShowPaymentModal(false);
    }

    useEffect(() => {
        async function getMembers() {
            const gymIds = gym?.members || [];
            const gymMembers: MemberState[] = [];
            for (let i = 0; i < gymIds.length; i++) {
                const docRef = doc(db, MEMBER_COLLECTION, gymIds[i])
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const member = docSnap.data() as MemberState;
                    gymMembers.push(member);
                }
            }
            dispatch(setMembers(gymMembers));
        }
        getMembers();
    }, [gym, showEditMemberModal])

    useEffect(() => {
        setJoinDate(new Date((memberSelected?.joinDate.seconds || 0) * 1000).toISOString().substring(0, 10))
        setMembershipExpiration(new Date((memberSelected?.membershipExpiration.seconds || 0) * 1000).toISOString().substring(0, 10))
    }, [memberSelected])

    return (
        <div className="dashboard-main">
            <MemberStats members={members} />
            <ControlPanel showHidden={showHidden} setShowHidden={setShowHidden} />
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        {mobileScreen ? null : <th>Emergency Contact</th>}
                        <th>Membership Expiration</th>
                    </tr>
                </thead>
                <tbody>
                    {members.map((member: MemberState) => {
                        if (!showHidden && member.hidden) return null;
                        const pastDue = new Date(member.membershipExpiration.seconds * 1000) < new Date();
                        return (
                            <tr key={member.id} className={`${pastDue ? "past-due" : ""} member-row`} onClick={() => {
                                setMemberSelected(member)
                                setShowNewMemberModal(true)
                            }} >
                                <td>{member.name}</td>
                                <td>{member.phone}</td>
                                {mobileScreen ? null : <td>{member.emergencyContactName}</td>}
                                <td>{new Date(member.membershipExpiration.seconds * 1000).toLocaleDateString()}</td>
                            </tr>
                        )
                    })}
                    {/* if members length < 10, fill in with empty rows */}
                    {members.length < 15 && [...Array(15 - members.length)].map((_, index) => {
                        return (
                            <tr className='member-row' key={index}>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                {mobileScreen ? null : <td> </td>}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <Modal setShow={setShowNewMemberModal} show={showNewMemberModal}>
                <h1>{memberSelected?.name}</h1>
                <h3>{memberSelected?.hidden ? "Inactive" : "Active"}</h3>
                <h3>Payments: {memberSelected?.paymentUpToDate ? <span style={{ "color": "green" }}>CURRENT</span> : <span style={{ "color": "red" }}>EXPIRED</span>}</h3>
                <div className="member-modal-row">
                    <span className='member-modal-label'>Phone:</span>
                    <a href={`tel:+${memberSelected?.phone}`} className='member-modal-content' >{memberSelected?.phone}</a>
                </div>
                <div className="member-modal-row">
                    <span className='member-modal-label'>Email:</span>
                    <a href={`mailto:${memberSelected?.email}`} className='member-modal-content' >{memberSelected?.email}</a>
                </div>
                <div className="member-modal-row">
                    <span className='member-modal-label'>Emergency Contact:</span>
                    <span className='member-modal-content' >{memberSelected?.emergencyContactName}</span>
                </div>
                <div className="member-modal-row">
                    <span className='member-modal-label'>Emergency Contact Phone:</span>
                    <a href={`tel:+${memberSelected?.emergencyContactPhone}`} className='member-modal-content' >{memberSelected?.emergencyContactPhone}</a>
                </div>
                <div className="member-modal-row">
                    <span className='member-modal-label'>Join Date:</span>
                    <span className='member-modal-content' >{new Date((memberSelected?.joinDate.seconds || 0) * 1000).toLocaleDateString()}</span>
                </div>
                <div className="member-modal-row">
                    <span className='member-modal-label'>Membership Expiration:</span>
                    <span className='member-modal-content' >{new Date((memberSelected?.membershipExpiration.seconds || 0) * 1000).toLocaleDateString()}</span>
                </div>
                <hr />
                <div className="button-container">
                    <button className='custom-button-primary' onClick={() => {
                        setShowNewMemberModal(false);
                        setShowPaymentModal(true);
                    }}>payments</button>
                    <button onClick={() => handleEditMember()} className='custom-button-primary'>Edit</button>
                    <button onClick={() => handleRemoveMember(memberSelected?.id)} className='custom-button-primary'>Delete</button>
                </div>
            </Modal>
            <Modal setShow={setShowEditMemberModal} show={showEditMemberModal}>
                <div className="new-member-body">

                    <h1>edit member</h1>

                    <label htmlFor="member-name">Member Name</label>
                    <input defaultValue={memberSelected?.name} ref={nameRef} id="member-name" type="text" placeholder='name' />

                    <label htmlFor="member-phone">Phone</label>
                    <input defaultValue={memberSelected?.phone} ref={phoneRef} id="member-phone" type="text" placeholder='number' />

                    <label htmlFor="member-email">Email</label>
                    <input defaultValue={memberSelected?.email} ref={emailRef} id="member-email" type="text" placeholder='email' />

                    <label htmlFor="member-emergency-contact-name">Emergency Contact Name</label>
                    <input defaultValue={memberSelected?.emergencyContactName} ref={emergencyContactNameRef} id="member-emergency-contact-name" type="text" placeholder='name' />

                    <label htmlFor="member-emergency-contact-phone">Emergency Contact Phone</label>
                    <input defaultValue={memberSelected?.emergencyContactPhone} ref={emergencyContactPhoneRef} id="member-emergency-contact-phone" type="text" placeholder='number' />

                    <label htmlFor="member-join-date">Join Date</label>
                    <input onChange={(e) => setJoinDate(e.target.value)} value={joinDate} ref={joinDateRef} id="member-join-date" type="date" placeholder='date' />

                    <label htmlFor="member-membership-expiration">Membership Expiration</label>
                    <input onChange={(e) => setMembershipExpiration(e.target.value)} value={membershipExpiration} ref={membershipExpirationRef} id="member-membership-expiration" type="date" placeholder='date' />

                    <label htmlFor="member-membership-expiration">Set Member Inactive</label>
                    <input defaultChecked={memberSelected?.hidden} ref={memberHidden} type="checkbox" />
                    <hr />
                    <button className='custom-button-primary' onClick={() => handleSaveMember(memberSelected?.id as string)}>Save Member</button>

                </div>
            </Modal>

            <Modal setShow={setShowPaymentModal} show={showPaymentModal}>
                <div className="payment-modal-body">
                    <h1>Payment</h1>
                    <div className="payment-modal-content">
                        <h3>Payment Status: {paymentSuccess ? <span style={{ "color": "green" }}>SUCCESS</span> : paymentError ? <span style={{ "color": "red" }}>ERROR</span> : "PENDING"}</h3>
                        <p>{paymentError}</p>
                        <p>To update the member to a "PAID" status and process the payment yourself, chose the "PAID" button. Otherwise, use our checkout with stripe to process a credit card!</p>
                    </div>
                    <hr />
                    <div className="button-container">
                        <button className='custom-button-primary' onClick={setMemberAsPaid} >Paid</button>
                        <button onClick={() => {
                            setShowPaymentModal(false);
                            setShowCheckout(true);
                        }} className='custom-button-primary'>Stripe checkout</button>
                    </div>
                </div>
            </Modal>

            <Modal setShow={setShowCheckout} show={showCheckout} >
                <div className="checkout-modal-body">
                    <h1>Checkout</h1>
                    <div className="checkout-modal-content">
                        <h3>Payment Status: {paymentSuccess ? <span style={{ "color": "green" }}>SUCCESS</span> : paymentError ? <span style={{ "color": "red" }}>ERROR</span> : "PENDING"}</h3>
                        <p>{paymentError}</p>
                        <p>Enter the amount you would like to charge the member, then click the "Checkout" button to process the payment.</p>
                        <select name="paymentOptions" id="">
                            <option value="oneTime">One Time Payment</option>
                            <option value="monthly">Monthly Payment</option>
                            <option value="yearly">Yearly Payment</option>
                        </select>
                        <input type="number" value={paymentAmount} onChange={(e) => setPaymentAmount(e.target.value as unknown as number)} />
                    </div>
                    <hr />
                    <div className="button-container">
                        <button className='custom-button-primary' onClick={() => setShowCheckout(false)} >Cancel</button>
                        <button className='custom-button-primary' onClick={() => setShowCheckout(false)} >Checkout</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
