import Nav from './Components/Nav';
import Dashboard from './Pages/Members';
import "./App.scss";
import backgroundImg from "./Images/homepage.jpg"

function App() {

  function handleLogin() {
    const loginButton = document.getElementById('login-button')
    if (loginButton) {
      loginButton.click()
    }
  }
  return (
    <div className="App">
      <Nav />
      <main className='homepage-main'>
        <div className="cta-container">
          <h1>Ultimate Gym</h1>
          <h1>Management</h1>
          <div className="signup-button-container">
            <button onClick={handleLogin} className="custom-button-tertiary">Sign Up</button>
            {/* <button onClick={handleLogin} className="signup-button">Login</button> */}
          </div>
        </div>
      </main>
      <section id="features" className='features'>
        <div className="card">
          <h2>Feature 1</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam exercitationem possimus ab magnam. Quae necessitatibus et esse minima sit vitae illo sint fugiat. Vero laboriosam repellendus quae earum, alias mollitia.</p>
        </div>
        <div className="card">
          <h2>Feature 2</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam exercitationem possimus ab magnam. Quae necessitatibus et esse minima sit vitae illo sint fugiat. Vero laboriosam repellendus quae earum, alias mollitia.</p>
        </div>
        <div className="card">
          <h2>Feature 3</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam exercitationem possimus ab magnam. Quae necessitatibus et esse minima sit vitae illo sint fugiat. Vero laboriosam repellendus quae earum, alias mollitia.</p>
        </div>
      </section>
      <section className="about" id="about">
        <div className="about-container">
          <h1>About Us</h1>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam exercitationem possimus ab magnam. Quae necessitatibus et esse minima sit vitae illo sint fugiat. Vero laboriosam repellendus quae earum, alias mollitia.</p>
        </div>
      </section>
    </div>
  );
}

export default App;
