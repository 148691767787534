import React from 'react'
import "./styles.scss";

export default function MemberStats({ members }: { members: MemberState[] }) {

  // Function to count members that joined in the last month
  function countNewMembers() {
    let count = 0;
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    for (let i = 0; i < members.length; i++) {
      const member = members[i];
      if (member.joinDate) {
        const joinDate = member.joinDate.toDate();
        if (joinDate > lastMonth) {
          count++;
        }
      }
    }
    return count;
  }

  function countExpiredMembers() {
    let count = 0;
    const today = new Date();
    for (let i = 0; i < members.length; i++) {
      const member = members[i];
      if (member.membershipExpiration) {
        const expirationDate = member.membershipExpiration.toDate();
        if (expirationDate < today) {
          count++;
        }
      }
    }
    return count;
  }

  return (
    <div className="stats-body">
      <div className="stat-cell">
        <span>{members.length}</span>
        <span>Members</span>
      </div>
      <div className="stat-cell">
        <span>
          {countNewMembers()}
        </span>
        <span>
          New
        </span>
        <span>
          Members
        </span>
      </div>
      <div className="stat-cell">
        <span>
          {countExpiredMembers()}
        </span>
        <span>
          Expired
        </span>
        <span>
          Members
        </span>
      </div>
    </div>
  )
}
