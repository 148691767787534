import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from "../../store";

const initialState: UserState = {
  user: null,
  userID: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser(state, action: PayloadAction<string>) {
      state.userID = action.payload;
    },
    setUser(state, action: PayloadAction<GymState>) {
      state.user = action.payload;
    },
    logOut(state) {
      state.user = null;
    },
  },
});

export const { setUser, logOut, loginUser } = userSlice.actions;
export const selectUser = (state: RootState) => state.user
export default userSlice.reducer;
