import { useEffect } from 'react'
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import './style.scss';
import { store } from '../../Utilities/Redux/store';
import { useAppDispatch, useAppSelector } from '../../Utilities/Redux/hooks'
import { NavLoggedIn } from '../Nav';
import { httpsCallable, HttpsCallable } from 'firebase/functions'
import { functions } from '../../Firebase/firebaseConfig';
import { updateStripeData } from '../../Utilities/Redux/Features/Gym/gym'
import { saveGymStateToFirebase } from '../../Firebase/stateSync';
import { loginUser } from '../../Utilities/Redux/Features/Login/user';
import { auth } from '../../Firebase/firebaseConfig';
import Loading from '../../Pages/Loading';
export default function DashboardTemplate() {
  const user = useAppSelector(state => state.user.userID)
  const stripeData = useAppSelector(state => state.gym.stripeData || null);
  const dispatch = useAppDispatch();


  auth.onAuthStateChanged(function (user) {
    if (user?.uid) {
      dispatch(loginUser(user.uid))
    } else {
      console.log("no user")
    }
  });

  useEffect(() => {
    if (stripeData && stripeData.id) {
      const getStripeAccount: HttpsCallable = httpsCallable(functions, 'updateStripeAccount')
      getStripeAccount({ accountID: stripeData.id })
        .then((response: any) => {
          const { data } = response;
          dispatch(updateStripeData(data));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [])

  if (!user) return <Loading />
  
  return (
    <>
      <NavLoggedIn />
      <div className="template-main">
        <Sidebar />
        <div className="template-content">
          <Outlet />
        </div>
      </div>
    </>
  )
}
