import React, { useRef } from 'react'
import "./style.scss"
import { stat } from 'fs'
import moment from 'moment';
import {v4 as generateID} from "uuid"

export default function Modal({
    hide,
    setEvents,
    start,
    setStart,
    end,
    setEnd,
    save
}: {
    hide: Function,
    setEvents: Function,
    start: Date | null,
    setStart: Function,
    end: Date | null,
    setEnd: Function,
    save: Function
}) {
    const titleRef = useRef<HTMLInputElement>(null)
    const descriptionRef = useRef<HTMLTextAreaElement>(null)
    const locationRef = useRef<HTMLInputElement>(null)
    const reoccuringRef = useRef<HTMLInputElement>(null)
    const allDayRef = useRef<HTMLInputElement>(null)
    const [startTime, setStartTime] = React.useState<Date | null>(start)
    const [endTime, setEndTime] = React.useState<Date | null>(end)

    function handleSaveEvent() {
        const newEvents: any[] = [];
        if (startTime && endTime && reoccuringRef.current?.checked) {
            let newStart = startTime
            let newEnd = endTime
            const endDate = new Date(startTime);
            endDate.setFullYear(endDate.getFullYear() + 1);
            const newReoccuringID = generateID();

            while (newStart < endDate) {
                newEvents.push({
                    id: generateID(),
                    reoccuringID: newReoccuringID,
                    start: moment(newStart).toDate(),
                    end: moment(newEnd).toDate(),
                    title: titleRef.current?.value || "",
                    description: descriptionRef.current?.value || "",
                    location: locationRef.current?.value || "",
                    reoccuring: reoccuringRef.current?.checked || false,
                    allDay: allDayRef.current?.checked || false
                })
                // push newStart and newEnd forward one week
                newStart = new Date(newStart.setDate(newStart.getDate() + 7))
                newEnd = new Date(newEnd.setDate(newEnd.getDate() + 7))
            }
        }
        else {
            newEvents.push({
                id: generateID(),
                reoccuringID:"",
                start: moment(startTime).toDate(),
                end: moment(endTime).toDate(),
                title: titleRef.current?.value || "",
                description: descriptionRef.current?.value || "",
                location: locationRef.current?.value || "",
                reoccuring: reoccuringRef.current?.checked || false,
                allDay: allDayRef.current?.checked || false
            })
        }
        setEvents((prev: any) => [...prev, ...newEvents])
        hide(false)
    }

    function handleTimeChange(e: any, type: string) {
        const time = e.target.value;
        const [hours, minutes] = time.split(":")
        const newTime = type === "start" ? new Date(startTime || new Date()) : new Date(endTime || new Date());
        newTime.setHours(parseInt(hours))
        newTime.setMinutes(parseInt(minutes))
        if (type === "start") {
            setStartTime(newTime)
            if(endTime && newTime > endTime) {
                const newEndTime = new Date(newTime)
                setEndTime(new Date(newEndTime.setMinutes(newEndTime.getMinutes() + 15)))
            }
        }
        else {
            setEndTime(newTime)
        }
    }

    return (
        <div className="schedule-modal-container">
            <div className="schedule-modal-body">
                {/* {start && end && <div className="schedule-modal-body-header">
                    <h1>{start.toLocaleDateString()}</h1>
                    <h1>{start.toLocaleTimeString()} - {end.toLocaleTimeString()}</h1>
                </div>} */}
                <div className="schedule-modal-body-content">
                    <div className="schedule-modal-body-content-inputs">
                        <div className="schedule-modal-body-content-inputs-input">
                            <label htmlFor="start">Start</label>
                            <input ref={titleRef} type="time" name="start" id="start"
                                step="900"
                                value={moment(startTime).format("HH:mm") || ""}
                                onChange={e => {
                                    handleTimeChange(e, "start")
                                }}
                            />
                        </div>
                        <div className="schedule-modal-body-content-inputs-input">
                            <label htmlFor="end">End</label>
                            <input ref={titleRef} type="time" name="end" id="end"
                                step="900"
                                value={moment(endTime).format("HH:mm") || ""}
                                onChange={e => {
                                    handleTimeChange(e, "end")
                                }}
                            />
                        </div>
                        <div className="schedule-modal-body-content-inputs-input">
                            <label htmlFor="title">Title</label>
                            <input ref={titleRef} type="text" name="title" id="title" />
                        </div>
                        <div className="schedule-modal-body-content-inputs-input">
                            <label htmlFor="description">Description</label>
                            <textarea draggable="false" ref={descriptionRef} name="description" id="description" />
                        </div>
                        <div className="schedule-modal-body-content-inputs-input">
                            <label htmlFor="location">Location</label>
                            <input ref={locationRef} type="text" name="location" id="location" />
                        </div>
                        <div className="schedule-modal-body-content-inputs-input">
                            <label htmlFor="reoccuring">Reoccuring *event will repeat for one year*</label>
                            <input ref={reoccuringRef} type="checkbox" name="reoccuring" id="reoccuring"
                            />
                        </div>
                        <div className="schedule-modal-body-content-inputs-input">
                            <label htmlFor="allDay">All Day</label>
                            <input ref={allDayRef} type="checkbox" name="allDay" id="allDay" />
                        </div>
                    </div>
                    <div className="schedule-modal-body-content-buttons">
                        <button className="custom-button-primary" onClick={() => hide(false)}>Cancel</button>
                        <button className="custom-button-primary" onClick={() => handleSaveEvent()}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
