import React, { useEffect } from 'react'
import { auth } from "../../../Firebase/firebaseConfig"
import firebase from 'firebase/compat/app'
// import { auth } from '../../../Firebase/firebaseConfig';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css'
import './style.scss'
import { useSelector, useDispatch } from 'react-redux'
import { loginUser, selectUser } from '../../../Utilities/Redux/Features/Login/user';
import { redirect } from 'react-router-dom';

export default function LoginModal({ hide }: any) {
    const dispatch = useDispatch()

    const uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: function (
                authResult: any,
                redirectUrl: any,
            ) {
                dispatch(loginUser(authResult.user.uid))
                hide(false);
                return false;
            },
            uiShown: function () {
                const loading = document.getElementById('loader')
                if (loading) {
                    loading.style.display = 'none'
                }
            },
        },
        signInFlow: 'popup',
        // signInSuccessUrl: '/loading',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
    }

    useEffect(() => {
        const ui =
            firebaseui.auth.AuthUI.getInstance() ||
            new firebaseui.auth.AuthUI(auth)
        ui.start('#firebaseui-auth-container', uiConfig)
    }, [])

    return (
        <div className="login-modal-body">
            <div className="login-body" >
                <h2>Login:</h2>
                <div id="firebaseui-auth-container"></div>
                <div id="loader">Loading...</div>
                <button className='custom-button-primary' onClick={() => hide(false)}>Close</button>
            </div>
        </div>
    )
}
