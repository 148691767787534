import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../Utilities/Redux/hooks'
import { store } from '../../Utilities/Redux/store';
import { setUser, loginUser } from '../../Utilities/Redux/Features/Login/user'
import './style.scss';
import LoginModal from './LoginModal';
import { auth } from '../../Firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'
// import Logo from "../../Images/logo-white.png"
import Logo from "../../Images/logo-black.png"
import * as Icon from "react-bootstrap-icons"

export default function Nav() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = React.useState(false)
  const [showMenu, setShowMenu] = useState(window.innerWidth > 768 ? true : false)
  const user = useAppSelector(state => state.user.userID)
  const dispatch = useAppDispatch()

  auth.onAuthStateChanged(function (user) {
    if (user) {
      dispatch(loginUser(user.uid))
    } else {
      console.log("no user")
    }
  });

  useEffect(() => {
    if (user) {
      navigate('/loading');
    }
  }, [user])

  return (
    <nav className='nav-main no-log-in-nav'>
      <div className="logo-container">
        <img src={Logo} alt="" />
      </div>
      <div className="dropdown-container">
        <Icon.MenuDown onClick={() => setShowMenu(!showMenu)} />
      </div>
      <div style={showMenu ? { "display": "flex" } : { "display": "none" }} className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#features">Features</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#about">About Us</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" onClick={() => setModalOpen(true)}>Login</a>
          </li>
          <button id="login-button" className='custom-button-primary' onClick={() => setModalOpen(true)}>Sign Up</button>
        </ul>
      </div>

      {modalOpen && <LoginModal hide={setModalOpen} />}
    </nav>
  )
}

export function NavLoggedIn() {
  const dispatch = useAppDispatch()


  auth.onAuthStateChanged(function (user) {
    if (user) {
      dispatch(loginUser(user.uid))
    } else {
      console.log("no user")
    }
  });

  function handleLogout() {
    auth.signOut().then(() => {
      window.location.href = '/';
    })
      .catch((error) => {
        console.log(error)
      });
  }

  return (
    <nav className='nav-main logged-in-nav'>
      <div className="logo-container">
        <img src={Logo} alt="" />
      </div>

      <div className="button-container">
        <button className='custom-button-primary' onClick={() => handleLogout()}>Logout</button>
      </div>

      {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button> */}

    </nav>
  )
}