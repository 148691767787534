import { useState, useEffect } from 'react'
import { HttpsCallable, httpsCallable } from 'firebase/functions'
import { useAppDispatch, useAppSelector } from '../../../../Utilities/Redux/hooks';
import { functions } from '../../../../Firebase/firebaseConfig';
import { updateStripeData } from "../../../../Utilities/Redux/Features/Gym/gym"
import { saveGymStateToFirebase } from '../../../../Firebase/stateSync';

export default function Payment() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useAppDispatch();
    const gym = useAppSelector(state => state.gym);
    const stripeData = useAppSelector(state => state.gym.stripeData);

    const createStripeAccount: HttpsCallable = httpsCallable(functions, 'createStripeAccount')
    const getStripeAccount: HttpsCallable = httpsCallable(functions, 'createStripeAccountLink')

    function handleStripeCreateAccount() {
        setLoading(true);
        createStripeAccount()
            .then((response: any) => {
                if (response.data) {
                    // setAccount(response.data);
                    dispatch(updateStripeData(response.data));
                    handleGetStripeAccount(response.data.id);
                }
            })
            .catch((error) => {
                console.error(error);
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleGetStripeAccount(ID: string | null = null) {
        const accountID = ID || stripeData.id;
        getStripeAccount({ accountID: accountID })
            .then((response: any) => {
                const { data } = response;
                window.location = data.url;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        if (gym?.id) saveGymStateToFirebase(gym);
    }, [stripeData])

    return (
        <div className="stripe-settings-body">
            <h1>Payment Settings</h1>
            <div className="stripe-settings-content">
                <div className="stripe-settings-section">
                    <h2>Stripe Account</h2>
                    <p>Connect your stripe account to accept payments</p>
                    {!stripeData && <button onClick={handleStripeCreateAccount} disabled={loading}>Create Stripe Account</button>}
                    {stripeData && <button onClick={() => handleGetStripeAccount(null)} disabled={loading}>Manage Stripe Account</button>}
                </div>
                <div className="stripe-settings-section">
                    <h2>Payment Options</h2>
                    <p>Set up payment options for your gym</p>
                </div>
            </div>
        </div>
    )
}
