import React from 'react'
import "./style.scss"
export default function InfoModal({
    data,
    show,
    setShow,
    deleteEvent,
    deleteReoccuringEvent
}: {
    data: ScheduleEvent,
    show: boolean,
    setShow: Function,
    deleteEvent: Function,
    deleteReoccuringEvent: Function
}) {
    return (
        <div className="info-modal-background">
            <div className="info-modal-body">
                <button className="info-modal-close" onClick={() => setShow(false)}>X</button>
                <h1>{data.title}</h1>
                <h3>{data.description}</h3>
                <h3>{data.location}</h3>
                <h3>{data.reoccuring ? "Reoccuring" : "Not Reoccuring"}</h3>
                <h3>{data.allDay ? "All Day" : "Not All Day"}</h3>
                <h3>{data.start.toLocaleString()}</h3>
                <h3>{data.end.toLocaleString()}</h3>
                <button className="custom-button-primary" onClick={() => deleteEvent(data)}>Delete</button>
                {data.reoccuring && <button className="custom-button-primary" onClick={() => deleteReoccuringEvent(data)}>Delete All</button>}
            </div>
        </div>
    )
}
