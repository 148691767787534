import React, { useEffect, useState } from 'react'
import { auth } from '../../Firebase/firebaseConfig';
import Autocomplete from 'react-google-autocomplete';
import { GeoPoint } from 'firebase/firestore';
import { geocode, fromAddress, setKey } from 'react-geocode';
import { saveGymStateToFirebase } from "../../Firebase/stateSync"
import { useNavigate } from 'react-router-dom';
import './style.scss';

export default function GymSignUp() {
    const navigate = useNavigate();
    const { currentUser } = auth;
    const autocompleteRef = React.useRef<any>(null)
    const [latLng, setLatLng] = useState<GeoPoint | null>(null);
    const [gymName, setGymName] = useState<string>('');
    const [gymAddress, setGymAddress] = useState<string>('');
    const [gymPhone, setGymPhone] = useState<string>('');

    async function handlePlaceSelected(place: any, address: string) {
        setGymAddress(address);
        if (
            place === undefined ||
            place.geometry === undefined ||
            place.geometry.location === undefined
        ) {
            // call geocode api to get geometry from address
            // console.log('GEOCODE: ', address)
            setKey('AIzaSyBsH9qX6ADQaQHuNdLZFiJNWdC-T3QqodE')
            fromAddress(address).then(
                (response: any) => {
                    const { lat, lng } = response.results[0].geometry.location
                    setLatLng(new GeoPoint(lat, lng))
                },
                (error: any) => {
                    console.error('no address available', error)
                },
            )
        } else {
            const {
                geometry: { location },
            } = place
            const lat = location.lat()
            const lng = location.lng()
            setLatLng(new GeoPoint(lat, lng))
        }

    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        console.log('submitting')
        if (!latLng) handlePlaceSelected(undefined, autocompleteRef.current.value)
        const gym: GymState = {
            members: [],
            affiliates: [],
            otherGyms: [],
            name: gymName,
            id: currentUser!.uid,
            address: gymAddress,
            phone: gymPhone,
            geoPoint: latLng!,
            events: [],
            stripeData: null,
        }
        saveGymStateToFirebase(gym)
        navigate('/loading')
    }

    useEffect(() => {
        if (!currentUser) {
            navigate('/')
        }
    }, [currentUser])

    return (
        <div className="gym-signup-body">
            <h1>Welcome to EZ-Dojo</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="gymName">Gym Name:</label>
                <input required type="text" name="gymName" id="gymName" onChange={(e) => setGymName(e.target.value)} />
                <label htmlFor="gymAddress">Gym Address:</label>
                <Autocomplete
                    id="gymAddress"
                    placeholder="123 Main St, City, State, Zip"
                    apiKey={'AIzaSyCTnBKG7TRf1Anoh5KjX2aiCLglX60AS28'}
                    ref={autocompleteRef}
                    onPlaceSelected={(place) => {
                        
                        handlePlaceSelected(place, autocompleteRef.current.value)
                    }}
                    options={{
                        types: [
                            'street_address',
                        ],
                        // fields: ['name', 'geometry'],
                    }}
                />
                <label htmlFor="phone" >Gym Phone Number:</label>
                <input required type="tel" id="phone" name="phone" placeholder="1234567890" pattern="[0-9]{10}" onChange={(e) => setGymPhone(e.target.value)} />

                <button type='submit'>Submit</button>
            </form>
        </div>
    )
}
