import React from 'react'
import './style.scss';

export default function Modal({ children, show, setShow }: { children: React.ReactNode, show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>> }) {
    if (!show) return (<></>)
    return (
        <div className="modal-background">
            <div className="modal-body">
                <div className="modal-close" onClick={() => setShow(false)}>X</div>
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    )
}
