import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getAuth, GoogleAuthProvider, connectAuthEmulator } from 'firebase/auth'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { initializeFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyAJLIUJWF8SSy0qXxuFVQ7FB2KKW17244I",
  authDomain: "ezdojo-b40ce.firebaseapp.com",
  projectId: "ezdojo-b40ce",
  storageBucket: "ezdojo-b40ce.appspot.com",
  messagingSenderId: "222903007156",
  appId: "1:222903007156:web:c92eca9fe29c982462e4e1",
  measurementId: "G-ZKBN19PLRK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const functions = getFunctions(app)
const db = initializeFirestore(app, { experimentalForceLongPolling: true })
const storage = getStorage(app)
const auth = getAuth(app)
const provider = new GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })

if (window.location.hostname === "localhost") {
  console.log("---local database---");
  // connectFunctionsEmulator(functions, 'localhost', 5001)
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
  connectAuthEmulator(auth, "http://localhost:9099");
}

const GYM_COLLECTION = "GYMS";
const MEMBER_COLLECTION = "MEMBERS";
const PRICING_COLLECTION = "PRICING";
const SCHEDULE_COLLECTION = "SCHEDULE";

export {
  app,
  functions,
  db,
  storage,
  auth,
  provider,
  analytics,
  GYM_COLLECTION,
  MEMBER_COLLECTION,
  PRICING_COLLECTION,
  SCHEDULE_COLLECTION
}