import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { db, GYM_COLLECTION } from "../../../../Firebase/firebaseConfig";
import { doc, setDoc, getDoc } from "firebase/firestore";

const initialState: GymState = {
  members: [],
  affiliates: [],
  otherGyms: [],
  name: "",
  id: "",
  address: "",
  phone: "",
  geoPoint: null,
  events: [],
  stripeData: null,
};

const gymSlice = createSlice({
  name: "gym",
  initialState,
  reducers: {
    addMember(state, action: PayloadAction<string>) {
      state.members.push(action.payload)
    },
    removeMember(state, action: PayloadAction<string>) {
      state.members = state.members.filter(
        (member) => member !== action.payload
      );
    },
    addAffiliate(state, action: PayloadAction<string>) {
      state.affiliates.push(action.payload);
    },
    removeAffiliate(state, action: PayloadAction<string>) {
      state.affiliates = state.affiliates.filter(
        (affiliate) => affiliate !== action.payload
      );
    },
    addOtherGym(state, action: PayloadAction<string>) {
      state.otherGyms.push(action.payload);
    },
    removeOtherGym(state, action: PayloadAction<string>) {
      state.otherGyms = state.otherGyms.filter(
        (otherGym) => otherGym !== action.payload
      );
    },
    setGym(state, action: PayloadAction<GymState>) {
      state.address = action.payload.address || "";
      state.affiliates = action.payload.affiliates || [];
      state.geoPoint = action.payload.geoPoint || null;
      state.id = action.payload.id || "";
      state.members = action.payload.members || [];
      state.name = action.payload.name || "";
      state.otherGyms = action.payload.otherGyms || [];
      state.phone = action.payload.phone || "";
      state.events = action.payload.events || [];
      state.stripeData = action.payload.stripeData || null;
      
    },
    updateGym(state, action: PayloadAction<string>) {
      setGymInFB(state, action);
    },
    updateStripeData(state, action: PayloadAction<any>) {
      state.stripeData = action.payload || null;
    }
  },
});

async function getGymFromFB(id: string): Promise<GymState | null> {
  const docRef = doc(db, GYM_COLLECTION, id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const gym: GymState = {
      members: docSnap.data()?.members || [],
      affiliates: docSnap.data()?.affiliates || [],
      otherGyms: docSnap.data()?.otherGyms || [],
      name: docSnap.data()?.name || "",
      id: docSnap.data()?.id || "",
      address: docSnap.data()?.address || "",
      phone: docSnap.data()?.phone || "",
      geoPoint: docSnap.data()?.geoPoint || null,
      events: docSnap.data()?.events || [],
      stripeData: docSnap.data()?.stripeData || null,
    };
    return gym;
  } else {
    return null;
  }
}

async function setGymInFB(state: GymState, action: PayloadAction<string>) {
  await setDoc(doc(db, GYM_COLLECTION, action.payload), {
    members: state.members,
    affiliates: state.affiliates,
    otherGyms: state.otherGyms,
    name: state.name,
    id: state.id,
    address: state.address,
    phone: state.phone,
  });
}

export const {addMember, setGym, updateStripeData} = gymSlice.actions;
export default gymSlice.reducer;
