import React from 'react'
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../Utilities/Redux/hooks'
import members from "../../../Images/members.png"
import home from "../../../Images/home.png"
import settings from "../../../Images/settings.png"
import './style.scss';
import * as Icon from "react-bootstrap-icons"

export default function Sidebar() {
  const dispatch = useAppDispatch()
  const user: GymState | null = useAppSelector((state) => state.gym)

  return (
    <div className="sidebar-main">
      <div className="sidebar-links">
        <Link to={"/dashboard/home"}>
          <Icon.Calendar3Week />
        </Link>
        <Link to={"/dashboard/members"}><img src={members}></img></Link>
        <Link to={"/dashboard/settings"}><img src={settings}></img></Link>
      </div>
    </div>
  )
}
