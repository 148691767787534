import React from 'react'
import Construction from '../../Components/Construction'
import Pricing from './SettingsPages/Pricing'
import Payment from './SettingsPages/Payment'
import './style.scss'

export default function Settings() {


    return (
        <div className="settings-body">
            <div className="settings-header"></div>
            <div className="settings-content">
                <Payment />

            </div>
        </div>
    )
}
